import {InfoOutlined} from '@mui/icons-material'
import {Box, Stack, Typography} from '@mui/material'

export const MenuItemTooltipTitle = ({title, message}: {title: string; message: string}) => {
  return (
    <Stack spacing={1} useFlexGap>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <InfoOutlined sx={{mr: 1}} />
        <Typography fontWeight="bold">{title}</Typography>
      </Box>
      <Typography>{message}</Typography>
    </Stack>
  )
}
